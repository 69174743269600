import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.secondary};
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 500;
    transition: all 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.text};
    }
  }
`

const Footer = () => (
  <Wrapper>
    <List>
    <Item>
    <center><img
            className='lazyload'
            data-src="/images/vastuullinen.webp"
            width="320"
            height="100"
            alt="Pelaa vastuullisesti"
          /></center>
<center><p><small>Pelaatko liikaa? Soita <b>0800 100 101</b> tai vieraile <a href="https://www.peluuri.fi/fi" TARGET="_blank">peluuri.fi</a></small></p></center>
      </Item>
      <Item>
      </Item>
      <Item>
      </Item>
      <Item>
     <center><div class="somekuvat"><a href="https://www.facebook.com/Kasinosivu-103058834932376" TARGET="_blank"><img
            className='lazyload'
            data-src="/images/facebook.webp"
            width="32"
            height="32"
            alt="Facebook"
          /></a></div>
          <div class="somekuvat"><a href="https://www.instagram.com/kasinosivu/" TARGET="_blank"><img
            className='lazyload'
            data-src="/images/instagram.webp"
            width="32"
            height="32"
            alt="Instagram"
          /></a></div>
          <div class="somekuvat"><a href="https://www.youtube.com/channel/UC9cZlJaSfHfZXaq200E7GUA" TARGET="_blank"><img
            className='lazyload'
            data-src="/images/youtube.webp"
            width="32"
            height="32"
            alt="Youtube"
          /></a></div>
         <div class="somekuvat"><a href="https://twitter.com/kasinosivu" TARGET="_blank"><img
            className='lazyload'
            data-src="/images/twitter.webp"
            width="32"
            height="32"
            alt="Twitter"
          /></a></div></center>
      <center><p>Email: info@kasinosivu.com</p></center>
      <center><a
          href="/yhteys/"
        >
          Ota yhteyttä / Contact us
        </a></center>
      </Item>
    </List>
  </Wrapper>
)

export default Footer
